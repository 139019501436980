/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Hero from '../../components/hero';
import Layout from '../../components/layout';
import Section from '../../components/section';
import SEO from '../../components/seo';
import ButtonGroup from '../../components/button-group';
import Button from '../../components/button';

const TenStepsPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={ location }
            noSignUpForm
            noCta
            hideHeader
            hideLinks
        >
            <SEO
                title="10 Steps To Becoming A Better Freelancer eBook"
                description="Real-world methods tested over a decade to improve your freelance fortunes."
                imageAlt="10 Steps To Becoming A Better Freelancer eBook"
            />
            <Hero
                imageName="10-steps-to-becoming-a-better-freelancer-cover.png"
                imageAlt="10 Steps To Becoming A Better Freelancer"
                imageCaption="Real-world methods tested over a decade to improve your freelance fortunes."
                regularImage
                borderImage
            >
                <h1>10 Steps To Becoming <br />A Better Freelancer</h1>
                <p>The principles in The 10 Steps To Becoming A Better Freelance will help you to get better at:</p>
                <ul>
                    <li><FontAwesomeIcon icon="check" />Marketing yourself</li>
                    <li><FontAwesomeIcon icon="check" />Getting paid what you&apos;re worth</li>
                    <li><FontAwesomeIcon icon="check" />Being time efficient</li>
                    <li><FontAwesomeIcon icon="check" />Finding focus</li>
                    <li><FontAwesomeIcon icon="check" />Prioritising self-care</li>
                    <li><FontAwesomeIcon icon="check" />Winning higher-value projects</li>
                    <li><FontAwesomeIcon icon="check" />Client negotiations</li>
                    <li><FontAwesomeIcon icon="check" />Running a solo-business</li>
                    <li><FontAwesomeIcon icon="check" />Achieving balance</li>
                </ul>
                <p>
                    Prepare to be armed with the knowledge to <strong>get paid what you&apos;re worth</strong>, <strong>work
                    smarter</strong> and <strong>find balance</strong> through freelancing.
                </p>
                <ButtonGroup>
                    <Button to="https://gum.co/NHvhIm?wanted=true" icon="download" text="Download now" color="green" external gumroadSingle />
                    <Button to="#keep-reading" icon="arrow-down" text="Keep reading" anchor />
                </ButtonGroup>
            </Hero>
            <Section narrow>
                <h2 id="keep-reading">Could you be a better freelancer?</h2>
                <p>Right now, are you:</p>
                <ul>
                    <li>
                        Finding it difficult to market yourself?
                    </li>
                    <li>
                        Struggling to attract well-paying clients?
                    </li>
                    <li>
                        Unsure how to price your products and services?
                    </li>
                    <li>
                        Lacking confidence in project negotiations?
                    </li>
                    <li>
                        Working lots of hours to make a living?
                    </li>
                    <li>
                        Unfulfilled due to a lack of choice in what you do?
                    </li>
                    <li>
                        Prone to procrastination and wasting time?
                    </li>
                    <li>
                        Conflicted between your work, family and social lives?
                    </li>
                    <li>
                        Having problems finding balance?
                    </li>
                </ul>
                <p>I&apos;ve been there. So I changed things by improving my freelancing game.</p>
                <p>That&apos;s how I know my 10 Steps To Becoming A Better Freelancer can get you ahead too.</p>
                <p>Prepare to be armed with the knowledge to get paid what you&apos;re worth, work smarter and find
                    balance through freelancing.</p>
                <Button className="gumroad-button" to="https://gum.co/NHvhIm?wanted=true" icon="download" text="Download now" color="green" external gumroadSingle />                <h2>Contents</h2>
                <p>The chapters of this book hold the precise action I take to create a well-paid, efficient and
                    flexible life as a freelancer.</p>
                <p>The 10 Steps To Becoming A Better Freelancer are:</p>
                <ol>
                    <li>Hold Yourself Accountable</li>
                    <li>Refine Your Position</li>
                    <li>Profile Your Ideal Client</li>
                    <li>Make Yourself Known</li>
                    <li>Understand Pricing Deeply</li>
                    <li>Become A Negotiator</li>
                    <li>Introduce Structure</li>
                    <li>Automate and Outsource</li>
                    <li>Do A Good Job, Hone Your Skills and Stay Relevant</li>
                    <li>Seek Balance</li>
                </ol>
                <p>10 Steps To Becoming A Better Freelancer contains 10,000 words of clear and concise information
                    across
                    66 pages.</p>
                <p>Each section is detailed and easy to follow, with some chapters split into sub-sections to make
                    things
                    even easier to digest.</p>
                <Button className="gumroad-button" to="https://gum.co/NHvhIm?wanted=true" icon="download" text="Download now" color="green" external gumroadSingle />
                <h2>My Story</h2>
                <p>I&apos;m a long-time freelance web developer who loves helping others get ahead by sharing my
                    experiences.</p>
                <p>The theory in 10 Steps To Becoming A Better Freelancer is exactly what I follow to make a great
                    living
                    from freelancing each month.</p>
                <p>For over a decade, I&apos;ve been freelancing remotely for companies around the world.</p>
                <p>I&apos;ve worked on projects for the likes of the NHS, Facebook, Umbro, TGI Fridays and BMW.</p>
                <p>But I didn&apos;t always have great clients who pay well, a flexible work routine and control of my
                    day-to-day life.</p>
                <p>It&apos;s taken years of work to build a successful freelancing career and find a healthy work/life
                    balance alongside it.</p>
                <p>Through 10 Steps To Becoming A Better Freelancer, I&apos;d love to share what I&apos;ve learned with
                    you so that you can apply it to your circumstances and improve them.</p>
                <Button className="gumroad-button" to="https://gum.co/NHvhIm?wanted=true" icon="download" text="Download now" color="green" external gumroadSingle />                <h2>Are You Ready To Become A Better Freelancer?</h2>
                <p>If your freelancing career is yet to take off, you&apos;re thinking about going freelance or even
                    if things
                    are ticking over nicely but you&apos;re ready to move it up a gear – there's value inside 10
                    Steps To
                    Becoming A Better Freelancer for you.</p>
                <p>Start your journey to becoming a better freelancer today by clicking the "I want this!" button
                    now.</p>
                <p>Pay what you like (it&apos;s FREE) and enjoy!</p>
                <Button className="gumroad-button" to="https://gum.co/NHvhIm?wanted=true" icon="download" text="Download now" color="green" external gumroadSingle />            </Section>
        </Layout>
    );
};

TenStepsPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

TenStepsPage.defaultProps = {
    location: {},
};

export default TenStepsPage;
